import { SearchIcon } from '@chakra-ui/icons';
import { Button, useDisclosure } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { configFile } from '@/config';
import { handleGTMClick } from '@/helpers/handleGTMClick';

export default function Search() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const SearchModal = dynamic(() => import('@/components/SearchModal'), {
    ssr: false,
  });

  return (
    <>
      <Button
        id="header_search"
        aria-label="button search"
        onClick={(e) => handleGTMClick(e, onOpen)}
        height="40px"
        width="44px"
        minW="0"
        bgColor={configFile.sixAccentColor}
        color={configFile.tertiaryAccentColor}
        alignItems="center"
        p="0"
        _hover={{ bgColor: configFile.sevenAccentColor }}
        display="flex"
      >
        <SearchIcon color={configFile.tertiaryAccentColor} />
      </Button>
      {isOpen && <SearchModal isOpen={isOpen} onClose={onClose} />}
    </>
  );
}
