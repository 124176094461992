import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Button, Collapse, Flex, Link as ChakraLink, Text, useDisclosure } from '@chakra-ui/react';
import Link from 'next/link';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { HeaderMenuData } from '@/Types/types';
import { configFile } from '@/config';
import Search from '@/components/MainPage/Search';
import { handleGTMClick } from '@/helpers/handleGTMClick';

interface Props {
  menu: any;
}

interface CardSources {
  [key: number]: string;
}

interface PathButtonIds {
  [key: string]: string;
}

const ICON_CARD_WITH_DESCRIPTION_SRCS: CardSources = {
  0: '/images/header/icon1.svg',
  1: '/images/header/icon3.svg',
  2: '/images/header/icon2.svg',
  3: '/images/header/icon4.svg',
};
const ICON_COLOR_CARD_WITH_DESCRIPTION_SRCS: CardSources = {
  0: '/images/header/icon1color.svg',
  1: '/images/header/icon3color.svg',
  2: '/images/header/icon2color.svg',
  3: '/images/header/icon4color.svg',
};
const ICON_CARD_SRCS_SERVICES: CardSources = {
  0: '/images/header/icon5.svg',
  1: '/images/header/icon8.svg',
  2: '/images/header/icon6.svg',
  3: '/images/header/icon9.svg',
  4: '/images/header/icon7.svg',
};
const ICON_COLOR_CARD_SRCS_SERVICES: CardSources = {
  0: '/images/header/icon5color.svg',
  1: '/images/header/icon8color.svg',
  2: '/images/header/icon6color.svg',
  3: '/images/header/icon9color.svg',
  4: '/images/header/icon7color.svg',
};
const ICON_CARD_SRCS_COMPANY: CardSources = {
  0: '/images/header/icon10.svg',
  1: '/images/header/icon11.svg',
};
const ICON_COLOR_CARD_SRCS_COMPANY: CardSources = {
  0: '/images/header/icon10color.svg',
  1: '/images/header/icon11color.svg',
};

const BUTTON_URL_ID: PathButtonIds = {
  '/sobre': 'header_about_us',
  '/ferramentas': 'header_tools',
  '/blog': 'header_blog',
  '/servicos/consultoria-em-seo': 'header_seo',
  '/servicos/conteudo': 'header_content',
  '/servicos/digital-analytics': 'header_analytics',
  '/servicos/local-seo': 'header_local_seo',
  '/cenarios/migracao-de-sites': 'header_migration',
  '/cenarios/seo-para-lojas-virtuais': 'header_ecommerce',
  '/cenarios/auditoria-de-seo': 'header_audit',
  '/cenarios/seo-para-blogs': 'header_blogs',
  '/cenarios/seo-para-startups': 'header_startups',
  '/contato': 'header_contact',
};

function Item({ subItem, i }: { subItem: HeaderMenuData; i: number }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <ChakraLink
      id={BUTTON_URL_ID[subItem.path]}
      onClick={handleGTMClick}
      key={i}
      as={Link}
      href={subItem.path}
      p="0 !important"
      w="auto"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        transition: 'color 2s ease',
      }}
    >
      <Flex padding="16px" gap="10px" alignItems="flex-start">
        <Image
          src={isHovered ? ICON_COLOR_CARD_SRCS_SERVICES[i] : ICON_CARD_SRCS_SERVICES[i]}
          alt="icon"
          width="24"
          height="24"
          style={{
            transition: 'transform 2s ease, opacity 2s ease',
            opacity: isHovered ? 1 : 0.8,
          }}
        />
        <Text
          fontSize="16px"
          lineHeight="24px"
          fontWeight="500"
          w="100%"
          color={isHovered ? 'blue.600' : 'gray.600'}
          style={{
            transition: 'color 2s ease',
          }}
        >
          {subItem.title}
        </Text>
      </Flex>
    </ChakraLink>
  );
}

function ItemWithDescription({ subItem, i, itemIndex }: { subItem: HeaderMenuData; i: number; itemIndex: number }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <ChakraLink
      id={BUTTON_URL_ID[subItem.path]}
      onClick={handleGTMClick}
      onAuxClick={handleGTMClick}
      key={i}
      as={Link}
      href={subItem.path}
      p="0 !important"
      w="auto"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        transition: 'color 2s ease',
      }}
    >
      <Flex padding="16px" maxWidth="419px" gap="10px" alignItems="flex-start">
        <Image
          src={
            /* eslint-disable */
            itemIndex === 0
              ? isHovered
                ? ICON_COLOR_CARD_SRCS_COMPANY[i]
                : ICON_CARD_SRCS_COMPANY[i]
              : isHovered
              ? ICON_COLOR_CARD_WITH_DESCRIPTION_SRCS[i]
              : ICON_CARD_WITH_DESCRIPTION_SRCS[i]
          }
          alt="icon"
          width="24"
          height="24"
          style={{
            transition: 'transform 2s ease, opacity 2s ease',
            opacity: isHovered ? 1 : 0.8,
          }}
        />
        <Flex direction="column">
          <Text
            fontSize="16px"
            lineHeight="24px"
            fontWeight="500"
            color={isHovered ? 'blue.600' : 'gray.600'}
            style={{
              transition: 'color 2s ease',
            }}
          >
            {subItem.title}
          </Text>
          {subItem.items.length > 0 && (
            <Text
              fontSize="12px"
              lineHeight="16px"
              fontWeight="400"
              color={isHovered ? 'gray.600' : 'gray.500'}
              style={{
                transition: 'color 2s ease',
              }}
            >
              {subItem.items[0].title}
            </Text>
          )}
        </Flex>
      </Flex>
    </ChakraLink>
  );
}

export default function DesktopNav({ menu }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [menuId, setMenuId] = useState<number | undefined>();

  const { t: translation } = useTranslation('aboutUs');

  const handleMouseOver = (index: number) => {
    setMenuId(index);
    onOpen();
  };

  const handleMouseOut = () => {
    onClose();
  };

  return (
    <Flex
      align="center"
      columnGap="24px"
      as="nav"
      display={{ base: 'none', lg: 'flex' }}
      onMouseLeave={() => handleMouseOut()}
    >
      {menu.map((item: HeaderMenuData, index: number) => (
        <Box border="none" key={item.id} width="100%">
          <Button
            aria-label="button menu"
            onMouseEnter={() => handleMouseOver(index)}
            fontWeight={500}
            color={configFile.tertiaryAccentColor}
            bgColor={index === menu.length - 1 ? configFile.fourthAccentColor : configFile.primaryAccentColor}
            borderRadius="8px"
            padding="24px"
            h="44px"
            _hover={
              index === menu.length - 1
                ? {
                    /* eslint-disable */
                    bgColor: configFile.fiveAccentColor,
                  }
                : {
                    bgColor: configFile.sixAccentColor,
                  }
            }
          >
            {index === 0 || index === 2 ? (
              <Flex gap="12px" alignItems="center">
                {item.title}
                {isOpen && menuId === index ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </Flex>
            ) : (
              <ChakraLink
                as={Link}
                id={BUTTON_URL_ID[item.path]}
                onClick={handleGTMClick}
                onAuxClick={handleGTMClick}
                href={item.path}
                p="0 !important"
              >
                {item.title}
              </ChakraLink>
            )}
          </Button>
          {index === 0 && (
            <Collapse in={isOpen} animateOpacity>
              {menuId !== undefined && index === menuId && (
                <Box
                  position="absolute"
                  left="0"
                  right="0"
                  width="100%"
                  backgroundColor="#FFFFFF"
                  padding="0"
                  boxShadow="xl"
                  zIndex={3}
                >
                  <Box backgroundColor={configFile.primaryAccentColor} width="100%" height="28px" />
                  <Box
                    backgroundColor="gray.100"
                    width="21vw"
                    height="272px"
                    position="absolute"
                    left="0"
                    zIndex="-1"
                  />
                  <Flex m="0 auto" maxWidth="1216px" gap="72px">
                    <Flex direction="column" gap="16px" p="40px 56px 0 0" bgColor="gray.100">
                      <Text whiteSpace="pre-line" fontSize="28px" lineHeight="36px" fontWeight="500">
                        {translation('weArePassionateAboutSEO.title.2')}
                      </Text>
                      <Box width="66px" height="4px" bgColor="teal.500" />
                    </Flex>
                    <Flex direction="column" gap="32px" m="40px 0 40px 0">
                      <Flex direction="column" gap="16px">
                        <Text
                          fontSize="14px"
                          lineHeight="20px"
                          textTransform="uppercase"
                          fontWeight="700"
                          color="blue.800"
                        >
                          {item.title}
                        </Text>
                        <Flex flexWrap="wrap">
                          {item.items.map((subItem: HeaderMenuData, i: number) => (
                            <ItemWithDescription subItem={subItem} i={i} itemIndex={index} key={i} />
                          ))}
                        </Flex>
                      </Flex>
                      <Flex direction="column" gap="24px">
                        <Text
                          fontSize="14px"
                          lineHeight="20px"
                          textTransform="uppercase"
                          fontWeight="700"
                          color="blue.800"
                        >
                          {translation('ourTools.title')}
                        </Text>
                        <Flex alignItems="center" justifyContent="center" gap="40px">
                          <ChakraLink
                            id="header_locasty"
                            as={Link}
                            href="https://locasty.com/"
                            onClick={handleGTMClick}
                            onAuxClick={handleGTMClick}
                            target="_blank"
                            rel="noreferrer"
                            p="0 !important"
                          >
                            <Image src="/images/default/locasty.svg" alt="Locasty" width={98} height={26} />
                          </ChakraLink>
                          <Image src="/images/default/ectools.svg" alt="Ectools" width={125} height={20} />
                          <ChakraLink
                            id="header_seodex"
                            as={Link}
                            href="https://seodex.io/discord"
                            onClick={handleGTMClick}
                            onAuxClick={handleGTMClick}
                            target="_blank"
                            rel="noreferrer"
                            p="0 !important"
                          >
                            <Image src="/images/default/seodex.svg" alt="SEOdex" width={88} height={18} />
                          </ChakraLink>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Box>
              )}
            </Collapse>
          )}
          {index === 2 && (
            <Collapse in={isOpen} animateOpacity>
              {menuId !== undefined && index === menuId && (
                <Box
                  position="absolute"
                  left="0"
                  right="0"
                  width="100%"
                  backgroundColor="#FFFFFF"
                  padding="0"
                  boxShadow="xl"
                  zIndex={3}
                >
                  <Box backgroundColor={configFile.primaryAccentColor} width="100%" h="28px" />
                  {/* Services */}
                  <Flex m="40px auto" maxWidth="1216px">
                    <Flex direction="column" gap="32px">
                      <Text
                        fontSize="14px"
                        lineHeight="20px"
                        textTransform="uppercase"
                        fontWeight="700"
                        color="blue.800"
                      >
                        {item.title}
                      </Text>
                      <Flex flexWrap="wrap">
                        {item.items.slice(0, 4).map((subItem: HeaderMenuData, i: number) => (
                          <ItemWithDescription subItem={subItem} i={i} itemIndex={index} key={i} />
                        ))}
                      </Flex>
                    </Flex>
                    <Box h="228px" w="1px" borderLeft="1px solid #E2E8F0" />
                    {/* Scenary */}
                    <Flex direction="column" gap="32px" marginLeft="32px">
                      {item.items.slice(4, 5).map((subItem: HeaderMenuData, i: number) => (
                        <React.Fragment key={i}>
                          <Text
                            fontSize="14px"
                            lineHeight="20px"
                            textTransform="uppercase"
                            fontWeight="700"
                            color="blue.800"
                          >
                            {subItem.title}
                          </Text>
                          <Flex flexWrap="wrap" alignItems="flex-start" w="100%">
                            {subItem.items.map((subSubItem: any, i: number) => (
                              <Item subItem={subSubItem} i={i} key={i} />
                            ))}
                          </Flex>
                        </React.Fragment>
                      ))}
                    </Flex>
                  </Flex>
                </Box>
              )}
            </Collapse>
          )}
        </Box>
      ))}

      <Box display={{ base: 'none', lg: 'block' }}>
        <Search />
      </Box>
    </Flex>
  );
}
